import { default as character_45sheetwWBLn3CZn0Meta } from "/tmp/build_2f4675ba/pages/game-elements/character-sheet.vue?macro=true";
import { default as indexxDRewLhj8rMeta } from "/tmp/build_2f4675ba/pages/index.vue?macro=true";
import { default as playGyuyLoZcbkMeta } from "/tmp/build_2f4675ba/pages/play.vue?macro=true";
export default [
  {
    name: "game-elements-character-sheet",
    path: "/game-elements/character-sheet",
    meta: character_45sheetwWBLn3CZn0Meta || {},
    component: () => import("/tmp/build_2f4675ba/pages/game-elements/character-sheet.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_2f4675ba/pages/index.vue")
  },
  {
    name: "play",
    path: "/play",
    component: () => import("/tmp/build_2f4675ba/pages/play.vue")
  }
]